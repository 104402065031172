<!-- 答题组件 -->
<template>
  <div class="topic-item-panel">
    <!-- 文章带题目的 -->
    <template v-if="topicItemInfo.questionType && (topicItemInfo.questionType === 3 || topicItemInfo.questionType == 4)">
      <p v-if="topicItemInfo.questionType === 3"><el-tag> 阅读理解</el-tag></p>
      <p v-if="topicItemInfo.questionType === 4"><el-tag>听力</el-tag></p>
      <p class="articleTitle">{{ topicItemInfo.articleTitle }}</p>
      <p class="articleTitle">{{ topicItemInfo.articleDes }}</p>
      <div class="readingCom" v-html="topicItemInfo.articleCon"></div>
      <div v-for="(q, key) in topicItemInfo.questionList" :key="key" style="margin-top: 20px">
        <p class="question-tit" v-if="q.questionType && q.questionType === 2" :id="step(q.value)">
          <span style="font-weight: bold; flex-shrink: 0;"> {{ q.value }}</span>.
          <audio controls controlsList="nodownload" :src="`/video-dev${q.file}`" v-if="q.file"></audio>
          <span class="tip-class">{{ q.topicType === 1 ? '单选' : (q.topicType === 2 ? '多选' : '判断') }}</span>
        </p>
        <p class="question-tit" v-else :id="step(q.num)">
          <span style="font-weight: bold; flex-shrink: 0;">{{ q.num }}</span>.
          {{ q.question }}
          <span class="tip-class">{{ q.topicType === 1 ? '单选' : (q.topicType === 2 ? '多选' : '判断') }}</span>
        </p>
        <ul class="options-box">
          <li :class="['option-item', computedClass(item, q)]" v-for="(item, index) in q.options" :key="index">
            <p class="item-tit">{{ item.num }}、{{ item.label }}</p>
          </li>
        </ul>
        <p v-if="q.answer === q.rightAnswer" class="answer-right">回答正确 <span>得分：{{ q.score }}分</span></p>
        <p v-else class="answer-wrong">正确答案：<span>{{ q.rightAnswerLabel }}</span> 得分：<span>{{ q.score }}</span></p>
        <p class="analyze">解析：{{ q.analyze }}</p>
      </div>
    </template>
    <!-- 纯题目 -->
    <template v-if="topicItemInfo.questionType && (topicItemInfo.questionType === 1 || topicItemInfo.questionType == 2)">
      <audio v-if="topicItemInfo.file" controls controlsList="nodownload"
        :src="`/video-dev${topicItemInfo.file}`"></audio>
      <p class="question-tit" v-if="topicItemInfo.questionType && topicItemInfo.questionType === 2"
        :id="step(topicItemInfo.value)" style="font-weight: bold; flex-shrink: 0;">
        {{ topicItemInfo.num }}、{{ topicItemInfo.question }}
        <span class="tip-class">{{ topicItemInfo.topicType === 1 ? '单选' : (topicItemInfo.topicType === 2 ? '多选' : '判断')
        }}</span>
      </p>
      <p class="question-tit" v-else :id="step(topicItemInfo.num)">
        <span style="font-weight: bold; flex-shrink: 0;"> {{ topicItemInfo.num }}</span>.
        {{ topicItemInfo.question }}
        <span class="tip-class">{{ topicItemInfo.topicType === 1 ? '单选' : (topicItemInfo.topicType === 2 ? '多选' : '判断')
        }}</span>
      </p>
      <ul class="options-box">
        <li :class="['option-item', computedClass(item, topicItemInfo)]" v-for="(item, index) in topicItemInfo.options"
          :key="index">
          <p class="item-tit">{{ item.num }}、{{ item.label }}</p>
        </li>
      </ul>
      <p v-if="topicItemInfo.answer === topicItemInfo.rightAnswer" class="answer-right">回答正确 <span>得分：{{
        topicItemInfo.score }}分</span></p>
      <p v-else class="answer-wrong">正确答案：<span>{{ topicItemInfo.rightAnswerLabel }}</span> 得分：<span>{{
        topicItemInfo.score }}</span></p>
      <p class="analyze">解析：{{ topicItemInfo.analyze }}</p>

    </template>
    <!-- 文章带题目的 -->
    <template v-if="topicItemInfo.questionType && (topicItemInfo.questionType === 5)">
      <div class="components"><span style="font-weight: bold; flex-shrink: 0; padding-top: 10px;"> {{ topicItemInfo.num
      }}.
        </span>
        <div class="readingCom" v-html="topicItemInfo.articleCon"></div>
      </div>
      <div class="answer" style="height: 115px;">
        <el-input  :disabled="true" v-model="topicItemInfo.answer" type="textarea" placeholder="请输入内容" style="height: 115px;"></el-input>
      </div>
      <p class="analyze">解析：{{ topicItemInfo.analyze }}</p>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      default: ''
    },
    topicItemInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      active: [],
      value1: null,
    }
  },
  watch: {
    value: {
      handler(val) {
        this.active = val || []
      },
      immediate: true
    }
  },
  methods: {
    computedClass(item, q) {
      console.log(item, q, 'item');
      let classTip = ''
      if ([1, 3].includes(q.topicType) && item.value === q.answer) { // 单选、判断
        if (q.answer == q.rightAnswer) {
          console.log(q.answer, item.value, 'qqqq');
          classTip = 'option-item-active'
        } else {
          classTip = 'wrong-style'
        }

      } else if (q.topicType === 2 && q.answer.includes(item.value)) { // 多选
        classTip = 'option-item-active'
      }
      return classTip
    },
    step(i) {
      return "step" + i
    },
  }
}
</script>
<style lang="less" scoped>
.topic-item-panel {
  padding: 7px 20px 30px;
  width: 100%;

  &>p {
    margin-bottom: 10px;
  }

  >:nth-child(2) {
    margin-top: 20px;
  }

  .question-tit {
    color: #333;
    font-size: 14px;
    margin-bottom: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    display: flex;
    align-items: center;

    audio {
      margin: 0 15px;
    }

    .tip-class {
      // margin-top: -2px;
      display: inline-block;
      width: 34px;
      height: 20px;
      text-align: center;
      border-radius: 3px;
      font-size: 13px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      line-height: 19px;
      color: #FFFFFF;
      word-break: keep-all;
      background: #037CE4;
      margin-left: 10px;
    }
  }

  .options-box {
    width: 100%;

    .option-item {
      width: 100%;
      background: #F7F8F9;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 10px;
      display: flex;
      border: 1px solid transparent;
      box-sizing: border-box;

      &:last-child {
        margin-bottom: 0;
      }

      .icon-img {
        width: 16px;
        height: 16px;
        margin-top: 3px;
        margin-right: 10px;
      }

      .item-tit {
        font-size: 13px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        white-space: pre-wrap;
      }

      .check-tip {
        padding: 5px;
        color: #db192a;
      }
    }

    // 单选题和多选题
    .option-item-active {
      background: #E9F5FF;

      .item-tit {
        color: #037CE4;
      }
    }

    // 答题正确
    .right-style {
      background: #EFF4FF;

      .item-tit {
        color: #4575E8;
      }
    }

    // 答题错误
    .wrong-style {
      background: #FFF0F0;

      .item-tit {
        color: #E73A39;
      }
    }
  }

  .readingCom {
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
    text-indent: 10px;

    ::v-deep {
      p {
        margin-top: 10px !important;
      }
    }

  }
}

.answer {
  width: 100%;
  margin-top: 30px;

  ::v-deep {
    .el-textarea__inner {
      height: 115px !important;
      min-height: 115px !important;
    }
  }

}

.components {
  display: flex;

}

.articleTitle {
  text-indent: 10px;
}

// 答题正确
.right-style {
  background: #E9F5FF;

  .item-tit {
    color: #037CE4;
  }
}

.answer-right {
    margin-top: 20px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #00C392;
    line-height: 22px;
  }
  .answer-wrong {
    margin-top: 20px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    span {
      font-size: 16px;
      font-family: Arial-BoldMT, Arial;
      font-weight: bold;
      color: #E8403F;
      line-height: 22px;
    }
  }
// 答题错误
.wrong-style {
  background: #FFF0F0;

  .item-tit {
    color: #E73A39;
  }
}

.analyze{
  font-size: 14px;
  margin-top: 10px;
}
</style>
  